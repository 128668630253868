<template>
  <div class="vap-page">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Form Drafts</h1>
    </div>
    <div class="vap-page-content">
      <div v-if="tableData" class="main-table-container vasion-html-table-default">
        <VasionTable
          :headerColumns="tableData.Columns.Values"
          :tableRows="tableData.Rows.Values"
          :hideColumns="hiddenColumns"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          @vasion-selection="handleRowSelect"
        />
      </div>
      <div v-else class="no-intems-dive">
        <div class="vasion-page-subheader instructional-message-div">
          It appears that you currently do not have any drafts saved.
        </div>
      </div>
    </div>
    <VasionGeneralModal
      id="draft-delete-confirmation"
      :confirmButtonText="'YES'"
      :rejectButtonText="'NO'"
      :modalType="'message'"
      :buttonGroup="'center'"
      :message="deleteMessage"
      :sync="showDeleteDialog"
      @confirmButtonClick="deleteDraft"
      @noButtonClick="showDeleteDialog = !showDeleteDialog"
    />
    <VasionSnackbar
      id="snackbar-form-draft"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import moment from 'moment'

export default {
  name: 'Drafts',
  components: {
    Loading,
  },
  data: function () {
    return {
      columns: {
        Values: [],
        ValueType: 'String',
      },
      deleteDraftId: 0,
      deleteMessage: '',
      drafts: [],
      hiddenColumns: ['_ID_', '_FormID_'],
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      showDeleteDialog: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      tableData: null,
    }
  },
  computed: {
    ellipsisButtonConfig() { return this.$store.state.security.ellipsisButtonConfig },
  },
  watch: {
  },
  async created() {
    this.isLoading = true

    await this.$store.dispatch('security/setEllipsisButtonConfig', 'form-drafts')

    this.drafts = await this.$store.dispatch('forms/getEFormDrafts')

    this.columns.Values = [
      '_ID_',
      '_FormID_',
      'Draft Name',
      'Form Name',
      'Created Date',
      'Modified Date',
      '_VasionEllipsisButton_',
    ]

    this.tableData = await this.buildTableData(this.columns, this.drafts)

    this.isLoading = false
  },
  methods: {
    async buildTableData(columns, sourceData) {
      const rows = await sourceData.map(item => {
        const values = [
          item.ID,
          item.EFormID,
          item.Name,
          item.EFormName,
          moment(item.CreatedDate).format("M/D/YYYY, h:mm A"),
          moment(item.ModifiedDate).format("M/D/YYYY, h:mm A"),
          'VasionEllipsisIcon',
        ]
        return { Values: values, ValueType: "String" }
      })

      const tableData = {
        Columns: columns,
        Rows: {
          Values: rows
        }
      }

      return tableData
    },
    async deleteDraft() {
      this.showDeleteDialog = false
      this.isLoading = true
      this.$amplitude.trackEvent('Form Draft Deleted', {} )
      const response = await this.$store.dispatch('forms/deleteEFormDraftById', this.deleteDraftId)

      if (response && response?.Value) {
        this.snackbarTitle = "Form Draft"
        this.snackbarSubTitle = "The form draft was successfully deleted."
        this.snackbarImage = true
        this.showSnackbar = true
        this.drafts = await this.$store.dispatch('forms/getEFormDrafts')
        this.tableData = await this.buildTableData(this.columns, this.drafts)
        this.deleteDraftId = 0
      } else {
        this.snackbarTitle = "Form Draft"
        this.snackbarSubTitle = response?.ResultMessage ? response?.ResultMessage : `An error occurred during deletion of the draft form.`
        this.snackbarImage = false
        this.showSnackbar = true
      }
      this.isLoading = false
    },
    editDraft(formName, formID, draftId) {
      this.$store.dispatch('forms/clearEform')
      this.$amplitude.trackEvent('Form Draft Edited', {} )
      // Implementation of the editing screen is required
      this.$router.push({ name: 'MyDraftForm', params: { formName, formID, draftId } })
    },
    ellipsisButtonClicked(payload) {
      if (payload?.buttonId && payload?.item?.Values) {
        const [draftId, formId, name] = payload.item.Values
        switch (payload.buttonId) {
          case 'edit-form-draft-button':
            this.editDraft(name, formId, draftId)
            break;
          case 'delete-form-draft-button':
            this.deleteMessage = `Are you sure you want to delete "${name}"?`
            this.deleteDraftId = draftId
            this.showDeleteDialog = true
            break;
          default:
            break;
        }
      }
    },
    handleRowSelect(data) {
      const [draftId, formID, formName] = data.Values
      this.editDraft(formName, formID, draftId)
    },
  },
}
</script>

<style lang="scss" scoped>
.inner-div {
  padding-top: 16px;
  margin-right: 8px;
  margin-left: 16px;
  text-align: center;
}

.instructional-message-div {
  margin-top: 24px;
}


.no-intems-dive {
  margin-top: 60px;
}

.grid-div {
  height: calc(100vh - 224px);
  overflow: auto;
  text-align: left;
}
</style>
