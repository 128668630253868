import { render, staticRenderFns } from "./Drafts.vue?vue&type=template&id=64850d24&scoped=true&"
import script from "./Drafts.vue?vue&type=script&lang=js&"
export * from "./Drafts.vue?vue&type=script&lang=js&"
import style0 from "./Drafts.vue?vue&type=style&index=0&id=64850d24&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64850d24",
  null
  
)

export default component.exports